<template>
  <main class="main">
    <div class="container">
      <BreadcrumbsComponent title="Ошибка 404" />
      <div class="not-found">
        <div class="not-found__content">
          <h2 class="not-found__title">Упс, что-то пошло не так!</h2>
          <div class="not-found__text">
            Страница, которую вы запрашивали, не существует. Возможно, она была удалена, или вы выбрали
            неверный адрес. Может быть, здесь вы найдёте то, что вам нужно:
          </div>
          <div class="not-found__buttons">
            <router-link class="btn-dark" :to="{ name: 'home' }">Главная страница</router-link>
            <router-link class="btn-dark" :to="{ name: 'services' }">Услуги</router-link>
            <router-link class="btn-dark" :to="{ name: 'calculator' }">Расчитать лизинг</router-link>
          </div>
        </div>
        <div class="not-found__image">
          <LottieComponent json="/public/images/yoga-developer.json" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import LottieComponent from "components/Lottie.vue";

export default {
  name: "NotFound",
  components: {
    LottieComponent,
    BreadcrumbsComponent,
  },
  async asyncData({ store, res }) {
    if (res) {
      res.status(404);
    }
    if (!store.state.navigator) {
      await store.dispatch("GET_CONTACTS_PAGE");
    }
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Leasing Trade",
      "Leasing Trade",
      "Leasing Trade",
      "Leasing Trade",
      "",
      "Leasing Trade"
    );
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
    };
  },
};
</script>

<style lang="stylus">
@import '~@/styles/global/global.styl';

.not-found {
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-gap: 40px;
  align-items: center;

  +below(1024px) {
    grid-template-columns: 1fr;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    font-weight: normal;
    font-size: 2em;
    line-height: 41px;
    text-transform: uppercase;
    color: main_dark;
    margin-bottom: 30px;
  }

  &__text {
    font-weight: normal;
    font-size: 1em;
    line-height: 26px;
    color: main_dark;
    margin-bottom: 20px;
    white-space: pre-line;
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__image {
    max-width: 450px;
    width: 100%;

    +below(1024px) {
      display: none;
    }

    svg {
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="lottie" ref="lottie"></div>
</template>

<script>
import lottie from "lottie-web";

export default {
  name: "LottieComponent",
  props: {
    json: {
      type: String,
    },
  },
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.lottie,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: this.json,
    });
  },
};
</script>

<template>
  <div class="breadcrumb">
    <ul class="breadcrumb__list">
      <li v-if="$route.name !== 'home'">
        <router-link :to="{ name: 'home' }">Главная</router-link>
      </li>
      <template v-if="$route.meta && $route.meta.parent && $route.meta.parent.length">
        <li v-for="(parent, index) in $route.meta.parent" :key="index">
          <router-link :to="{ name: parent.name, hash: parent.hash, params: parent.params }">{{
            parent.title
          }}</router-link>
        </li>
      </template>
      <li v-if="title">
        <span>{{ title }}</span>
      </li>
    </ul>
    <h1 class="breadcrumb__title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/breadcrumbs.styl"
</style>
